import React from 'react';
import DashboardLayout from './layout/DefaultLayout'

const withDefaultLayout = Component => props => (
  <DashboardLayout>
    <Component {...props} />
  </DashboardLayout>
)

export default withDefaultLayout
