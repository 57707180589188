import { navigate } from "@reach/router"
import { pathname  } from "./utils/string-helper"

const useNavigateNext = location => {

  const searchParams = new URLSearchParams(location.search)
  const next = pathname(searchParams.get('next'))

  return [
    searchParams,    
    (url, options={}) => {
      console.log('useNavigatenext', 'url', url, 'next', next, 'location.pathname', location.pathname) 
      if (next && next != undefined 
        && next.length && next != "auth" 
        && pathname(location.pathname) != next
        && pathname(url) != 'two-factor-authentication'
        && pathname(url) != next
        ){
        navigate( `/${next}` )
      } else if(options.next && url != options.next){
        navigate( `${url}?next=${pathname(options.next)}` )
      } else if (location.googleSignIn != undefined) {
        navigate(url,{state:{googleSignIn:location.googleSignIn}});
      }else{
        navigate(url)
      }
    }
  ]
}

export default useNavigateNext