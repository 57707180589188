import React, { createContext, useContext, useReducer } from 'react'
import { useAuthService } from './AuthServiceProvider'

const initialState = {
  user: {
    email: null,
    password: null
  },
  token: {}
}

const SET_AUTH = 'SET_AUTH'
const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
const SET_2FA_TOKEN = 'SET_2FA_TOKEN'

const authReducer = (state, action) => {
  switch(action.type) {
    case SET_AUTH:
      return {...state, user: {...state.user, ...action.payload} }
    case SET_AUTH_TOKEN:
      return {...state, token: {...state.token, ...action.payload} }
    case SET_2FA_TOKEN:
      return {...state, token: {...state.token, user: { ...state.token.user,  '2fa': {token: action.payload}} }}
    default:
      throw new Error('Invalid action type')
  }
}

export const AuthContext = createContext(initialState)

export const AuthContextProvider = ({children}) => {

  const [state, dispatch] = useReducer(authReducer, initialState)

  const handlers = {
      setAuth: (auth) => {
        return new Promise(resolve=>resolve(dispatch( {type: SET_AUTH, payload:auth} )))
      },
      setAuthToken: (token) => {
        return new Promise(resolve=>resolve(dispatch( {type: SET_AUTH_TOKEN, payload:token} )))
      },
      clearAuthToken: () => {        
        return new Promise(resolve=>resolve(dispatch( {type: SET_AUTH_TOKEN, payload:{} } )))
      },
      set2faToken: (token) => {  
        return new Promise(resolve=>resolve(dispatch( {type: SET_2FA_TOKEN, payload:token} )))
      }
    }

  return <AuthContext.Provider value={{state, handlers}} >
    {children}
  </AuthContext.Provider>

}

export const useAuth = () => {
  
  const [ state, handlers ] = useAuthService( useContext(AuthContext) )    
  return [ state, handlers ]
}
