import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

const initialState = {
  document: {},
  audit: {},
}

const SET_DOCUMENT = 'SET_DOCUMENT'
const SET_DOCUMENT_AUDIT = 'SET_DOCUMENT_AUDIT'

const documentReducer = (state, action) => {
  switch (action.type) {
    case SET_DOCUMENT:
      return {
        ...state,
        document: {
          ...state.document,
          audit: {
            ...state.document.audit,
            isChanged: true,
          },
          ...action.payload,
        },
      }
    case SET_DOCUMENT_AUDIT:
      return { ...state, document: { ...state.audit, ...action.payload } }
    default:
      throw new Error('Invalid action type')
  }
}

export const DocumentContext = createContext(initialState)

export const DocumentContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(documentReducer, initialState)
  const handlers = {
    setDocument: (document) => {
      return new Promise((resolve) => resolve(dispatch({ type: SET_DOCUMENT, payload: document })))
    },
  }

  return <DocumentContext.Provider value={{ state, handlers }}>{children}</DocumentContext.Provider>
}

DocumentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
