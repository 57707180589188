import React from 'react'
import { Router, useLocation } from "@reach/router"
import { DocumentContextProvider } from './common/DocumentContextProvider'
import Auth from './auth/components/Auth'
import withContextProvider from './common/withContextProvider'
import withSuspense from './common/withSuspense'
import withDefaultLayout from './common/withDefaultLayout'
import { useAuth } from './auth/AuthContextProvider'
import { pathname } from './common/utils/string-helper'

//Dashboard
const Dashboard = React.lazy(() => import('./common/views/dashboard/Dashboard'))
//Auth
const Login = React.lazy(() => import('./auth/components/Login'))
const Logout = React.lazy(() => import('./auth/components/Logout'))
//Customer
const CustomerList = React.lazy(() => import('./customer/components/CustomerList'))
const CustomerListWithProvider = withContextProvider(CustomerList, DocumentContextProvider)
const Customer = React.lazy(() => import('./customer/components/Customer'))
const CustomerWithProvider = withContextProvider(Customer, DocumentContextProvider)
//Receivables
const ReceivablesList = React.lazy(() => import('./receivable/components/ReceivablesList'))
const ReceivablesListWithProvider = withContextProvider(ReceivablesList, DocumentContextProvider)
//Billing
const BillingList = React.lazy(() =>
  import('./billing/components/BillingList'),
)
const BillingListWithProvider = withContextProvider(BillingList, DocumentContextProvider)
const Billing = React.lazy(() => import('./billing/components/Billing'))
const BillingWithProvider = withContextProvider(Billing, DocumentContextProvider)
//Payment
const PaymentList = React.lazy(() =>
  import('./payment/components/PaymentList'),
)
const PaymentListWithProvider = withContextProvider(PaymentList, DocumentContextProvider)
const Payment = React.lazy(() => import('./payment/components/Payment'))
const PaymentWithProvider = withContextProvider(Payment, DocumentContextProvider)

const PrivateRoute = props => {  
  const [ state, {isAuth, getCookie} ] = useAuth()
  const location = useLocation()
  let { as: Comp, ...restOfTheProps } = props;
  return isAuth() ? <Comp {...restOfTheProps } /> : <Auth {...props} path="/auth" />
}

const Routes = props => {
  return (
    <Router>
      <PrivateRoute as={withDefaultLayout(Dashboard)} {...props} path="/" />
      <PrivateRoute as={withDefaultLayout(Dashboard)} {...props} path="/home" />
      <PrivateRoute as={withDefaultLayout(Dashboard)} {...props} path="/dashboard" />
      <PrivateRoute as={withDefaultLayout(CustomerListWithProvider)} {...props} path="/customers" />
      <PrivateRoute as={withDefaultLayout(CustomerWithProvider)} {...props} path="/customers/:customerId" />
      <PrivateRoute as={withDefaultLayout(ReceivablesListWithProvider)} {...props} path="/receivables" />
      <PrivateRoute as={withDefaultLayout(BillingListWithProvider)} {...props} path="/billings" />
      <PrivateRoute as={withDefaultLayout(BillingWithProvider)} {...props} path="/billings/:billingId" />
      <PrivateRoute as={withDefaultLayout(PaymentListWithProvider)} {...props} path="/payments" />
      <PrivateRoute as={withDefaultLayout(PaymentWithProvider)} {...props} path="/payments/:paymentId" />
      <Login {...props} path="/login" />
      <Logout {...props} path="/logout" />
      <Auth {...props} path="/auth" />
    </Router>
  )
}

export default withSuspense(Routes)
