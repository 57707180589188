import React from 'react';
import './common/scss/style.scss'

import { AuthContextProvider } from './auth/AuthContextProvider';
import withContextProvider from './common/withContextProvider';

import Routes from './routes'
//import Maintenance from './under-maintenance/components/Maintenance'

const App = props => {
  return (
    <>
      <Routes {...props} />
    </>
  )
}

export default withContextProvider(App, AuthContextProvider)
