'use strict'

export const capitalize = (str=null) => {
  if(!str || str == undefined) {
    return ""
  }
  return `${str?.[0]?.toUpperCase()}${str?.slice(1)}`
}

export const camelCase = (str=null) => {
  if(!str || str == undefined) {
    return ""
  }
  return str?.toLowerCase()?.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr?.toUpperCase())
}

export const pathname = (path="") => {
  return path && path[0] == "/" ? path.replace("/","") : path
}

export const parsePathUri = (path="") => {
  return path ? path?.split('?')?.[0] : path
}

export const formatDecimal = (value) => {
  //return (Math.trunc(value * 100)) / 100
  return Number(value.toString().match(/^\d+(?:\.\d{0,2})?/))
}

export const stringSanitizer = (string) => {

  let sanitizeString = string

  sanitizeString = sanitizeString.replace(/\d+|^\s+|\s+$/g,'')
  sanitizeString = sanitizeString.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g,'')

  return sanitizeString;

}

export const formatJobType = (jobType) => {
  if (!jobType) return ' ';

  // Correct 'partime' to 'parttime'
  if (jobType.toLowerCase() === 'partime') {
    jobType = 'parttime';
  }

  // Capitalize the first letter
  const capitalized = jobType.charAt(0).toUpperCase() + jobType.slice(1);

  // Replace 'fulltime' or 'parttime' with 'Full-time' or 'Part-time'
  return capitalized.replace('time', '-time');
};

// export const pdfStringFormatter = (str) => {
//   if(str){
//     const specialWords = ['ptd', 'llc', 'pte', 'inc']; // Words to keep in uppercase

//     // Replace underscores and hyphens with spaces
//     const cleanedStr = str.replace(/[_-]/g, ' ');
  
//     return cleanedStr
//       .split(' ')
//       .map(word => {
//         return specialWords.includes(word.toLowerCase()) 
//           ? word.toUpperCase() 
//           : _.startCase(_.toLower(word));
//       })
//       .join(' ');
//   }
// }

export const getFileType = (fileType) => {
  return fileType.split('/').pop();
}


