import axios from 'axios'

export const authApi = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  timeout: 30000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_AUTH_API_KEY,
  },
})

export const ioApi = axios.create({
  baseURL: process.env.REACT_APP_IO_URL,
  timeout: 30000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_IO_API_KEY,
  },
})

export const billingApi = axios.create({
  baseURL: process.env.REACT_APP_BILLING_URL,
  timeout: 30000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_BILLING_API_KEY,
  },
})

console.log('process.env.REACT_APP_ENV', process.env.REACT_APP_ENV, process.env.AUTH_URL)
