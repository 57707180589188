import React from 'react'
import { CContainer } from '@coreui/react-pro'

const AppContent = ({ children }) => {
  return (
    <CContainer lg>
      { children }
    </CContainer>
  )
}

export default AppContent
