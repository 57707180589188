import React from 'react'
import { LocationProvider } from "@reach/router"
import { AppAside, AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'

const DefaultLayout = ({ children }) => {

  return (
    <>
      <LocationProvider>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
          <AppHeader />
          <div className="body flex-grow-1 px-3">
            <AppContent>
              { children }
            </AppContent>
          </div>
          <AppFooter />
        </div>
        <AppAside />
      </LocationProvider>
    </>
  )
}

export default DefaultLayout
