import axios from 'axios'
import { authApi, ioApi, billingApi } from './../common/serviceApi'
import { omit } from 'lodash'
import moment from 'moment'

const qs = require('querystring')

authApi.interceptors.request.use(
  request => {
    const token = getCookie('tkn-ath')
    console.log('axios interceptors request set auth', token)
    if(token){
      request.headers['Authorization'] = `${token}`
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

ioApi.interceptors.request.use(
  request => {
    const token = getCookie('tkn-ath')
    console.log('axios interceptors request set auth', token)
    if(token){
      request.headers['Authorization'] = `${token}`
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

billingApi.interceptors.request.use(
  request => {
    const token = getCookie('tkn-ath')
    console.log('axios interceptors request set auth', token)
    if(token){
      request.headers['Authorization'] = `${token}`
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

authApi.interceptors.response.use(
  response => response,
  error => new Promise((resolve,reject)=>{
    const req = error.config
    console.log('axios interceptors response err', error.response)
    if(error.response?.status==401 && error.response?.data?.details?.name=="TokenExpiredError"){
      //token expiry
      req._retry = true
      //const refresh_token = sessionStorage.getItem('tkn-ath-rfrsh')
      const refresh_token = getCookie('tkn-ath-rfrsh')
      refresh({refresh_token})
        .then(async(token)=>{
          setCookie('tkn-ath', token?.token)
          setCookie('tkn-ath-rfrsh', token?.refresh_token)

          await getAuthMe(token)
          return token
        })
        .then((token)=>{
          req['headers'] = {
            'Authorization': `${token?.token}`
          }

          if(req?.data)
            req['data'] = JSON.parse(req?.data)

          return axios(req)          
        })
        .then(data=>{
          resolve(data)
        })
        .catch(err=>{
          reject(err)
        })
    } else {
      reject(error)
    }
  })
)

const getCookie = name => {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  console.log('getCookie matches', matches)
  return matches ? decodeURIComponent(matches[1]) : null;
}

const setCookie = (name, value, options = {}) => {

  options = {
    path: '/',
    // add other defaults here if necessary
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  } else {
    //set default expiry
    const dt = new Date()
    dt.setMinutes( dt.getMinutes() + 60 )
    options.expires = dt.toUTCString()
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
  console.log('setCookie',document.cookie)
}

const extendCookie = () => {
  const currentToken = getCookie('tkn-ath')
  const currentRfshToken = getCookie('tkn-ath-rfrsh')
  if(currentToken) {
    setCookie('tkn-ath', currentToken)
    setCookie('tkn-ath-rfrsh', currentRfshToken)
  }
}

const login = auth => {
  return authApi.post('auth',{
    email: auth.email.toLowerCase(),
    password: auth.password
  })
  .then(auth=>auth.data)
}

const refresh = auth => {
  return authApi.post('auth',{
    refresh_token: auth?.refresh_token
  })
  .then(auth=>auth.data)  
}

const getAuthGoogleUrl = (query=null)  => {
  let url = 'auth/google/url'
  if(query){
    url += `?${qs.stringify(query)}`
  }
  return authApi.get(url)
    .then(url=>url.data)
}

const getAuthMe = (token, options={})  => {
  return authApi.get('auth/me', options)
    .then(url=>url.data)
    .then(data => {
      const currentProfile = data?.personProfiles
        ?.filter(profile=>profile && profile?.status=="active")?.[0] || {}

      if(
        (
          ['admin'].includes(data?.role)
          //['admin'].includes(currentProfile?.profile_doc_type)
        ) ||
        ['super-admin'].includes(data?.role)
      )
          return {...data, ...token}
      
      return Promise.reject({ 
        response: {
          data: {
            error: "[INVALID_PASSWORD] Invalid Password" 
          }
        }
      })
    })
}

const sendRegistrationInvitation = (invite) => {
  return authApi.post('auth/registration/invite',invite)
    .then(auth=>auth.data)  
}

const sendResendRegistrationInvitation = (invite) => {
  return authApi.patch('auth/registration/invite',invite)
    .then(auth=>auth.data)  
}

const verifyRegistrationInvitation = (invite) => {
  return authApi.patch('auth/registration/invitation/verify', invite)
    .then(auth=>auth.data)
}

const verifyRegistrationInvitationByEmail = (email) => {
  return authApi.get(`invitations?email=${encodeURIComponent(email)}`)
    .then(auth=>auth.data)
    .then(invites=>{
      return invites
        ?.filter((invite)=>invite?.doc_type==='invitation'&&invite?.type==='profile-registration')
        ?.sort((a,b) => moment(b.created_at) - moment(a.created_at))[0] || []
    })
}

const register = person => {
  return authApi.post('auth/register', person)
    .then(person=>person.data)
}

const sendChangePasswordRequest = request => {
  return authApi.post('auth/forgot-password', request)
    .then(request=>request.data)
}

const changePassword = password => {
  return authApi.post('auth/change-password', password)
    .then(password=>password.data)  
}

const sso = (token, refresh) => {
  //sessionStorage.setItem('tkn-ath', token) 
  //sessionStorage.setItem('tkn-ath-rfrsh', refresh)
  setCookie('tkn-ath', token)
  setCookie('tkn-ath-rfrsh', refresh)
  return getAuthMe({token, refresh})    
}

const toggleShowPassword = (event, valueObject, setValueObject) => {
  event.preventDefault()
  setValueObject({ ...valueObject, showPassword: !valueObject.showPassword })
};

const emailValidation = (email, err, setErr) => {
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if(email && regex.test(email?.trim()) === false){
    const errObj = {
      email: 'Invalid email. Please try again.'
    }
    setErr({...err, errObj})
    return false
  }
  else {
    const errObj = omit(err?.errObj, ['email'])
    setErr({...err, errObj}) 
    return true
  }
}

const updateUserPassword = (passwords) => {
  return authApi.patch(`me/change-password`, passwords)
    .then(user=>user.data)  
}

const getLogSessions = () => {
  return authApi.get(`auth/my-sessions`)
    .then(sess=>{
      return sess?.data
            ?.sort((a,b) => b.createdAt-a.createdAt)
            .splice(0,5)
    })  
}

const request2faCode = (request) => {
  return authApi.post(`auth/security/two-factor/request`, request)
    .then(user=>user.data?.token)
}

const verify2faCode = (request) => {
  return authApi.post(`auth/security/two-factor/verify`, request)
    .then(twofa=>twofa)
}

const enableUser2faPref = (request) => {
  return authApi.patch(`me/preferences`, request)
    .then(pref=>pref)
}

const updateUserProfilePhoto = (uid, request) => {
  return authApi.patch(`persons/${uid}`, request)
    .then(user=>user)
}

export const useAuthService = ({state, handlers}) => {

  const options = {}
  const { setAuthToken, clearAuthToken, set2faToken } = handlers
  const services = {
    login: (auth) => {
      return login(auth)
        .then(token=>{
          return sso(token.token, token.refresh_token)
        })
        .then(token=> {
          setAuthToken(token)
          return token?.user
        })
    },
    sso: (token,refresh) => {
      return sso(token, refresh)
        .then(token=> {
          setAuthToken(token)
          return token
        })
    },
    refresh,
    logout: () => {
      setCookie('tkn-ath', "")
      setCookie('tkn-ath-rfrsh', "")
      console.log('logout clear cookie', getCookie('tkn-ath'))
      return clearAuthToken()
    },
    getCookie,
    isAuth: () => {
      extendCookie()
      return (![null, "null"].includes(getCookie('tkn-ath'))) && state?.token?.token?.length > 0 
    },
    getLogSessions: () => {
      return getLogSessions()
      .then(data=> {
        return data.map(sess => {
          let ua = sess?.device_info?.ua?.split('/')
          let osName = sess?.device_info?.os?.name || 'Unknown'
          let browserName = sess?.device_info?.browser?.name || ua?.[0]
          let fullformatname = [osName, browserName].join('/')

          const custom_format = {
            fullformatname,
            osName,
            browserName,
            browserFull: sess?.device_info?.browser?.name || sess?.device_info?.ua
          }
          return {...sess, ...{device_info: {...sess.device_info, custom_format: custom_format}} }
        })
      })
    },
    request2faCode: (req) => {
      return request2faCode(req)
        .then(token=>{
          set2faToken(token)
          return token
        })
    },
    updateUserProfilePhoto : (person) => {
      if(person?.personProfiles?.[0].profile_photo_path === "") {

        const uid = person?.personProfiles?.[0]?.person_uid
        const person_photo_url = person?.profile_photo_path

        const personProfiles = {
          person_photo_url: person_photo_url
        }
        return updateUserProfilePhoto(uid, personProfiles)
      }
    },
    updateUserPassword,
    getAuthGoogleUrl,
    getAuthMe,
    sendRegistrationInvitation,
    sendResendRegistrationInvitation,
    verifyRegistrationInvitation,
    verifyRegistrationInvitationByEmail,
    register,
    sendChangePasswordRequest,
    changePassword,
    toggleShowPassword,
    emailValidation,
    verify2faCode,
    enableUser2faPref,
    setCookie,
  }
  return [
    state, 
    {
      ...handlers,
      ...services
    }
  ]
}
