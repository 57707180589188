import React from 'react'

const withContextProvider = (Component, ContextProvider) => (props) =>
  (
    <ContextProvider>
      <Component {...props} />
    </ContextProvider>
  )

export default withContextProvider
